<template>
<v-container>
  <v-row>
    <v-col cols="12">

      <v-card>
        <v-card-title primary-title>
          <v-toolbar tile dense dark color="primary">
            Consulta de Inventarios por capa
          </v-toolbar>
          
          <v-spacer></v-spacer>
          
          <v-btn color="success" 
            small 
            @click="consultar()">
            Consultar

            <v-icon small>mdi-magnify</v-icon>
          </v-btn>
          
          <!-- BOTON DE XLS   -->
          <v-btn 
            class="ma-2" 
            small dark color="warning"
            @click="exportar()">
            <v-icon>mdi-download</v-icon>
          </v-btn>
          
          <!-- BOTON PDF -->
          <v-btn
            class='ma-2' 
            small color="secondary"
            @click="crearPDF()">
            <v-icon>mdi-printer</v-icon>
          </v-btn>

        </v-card-title>

        <v-card-text>
          <v-row>

            <!-- FECHA INICIAL -->
            <v-col cols="12" xl="2" lg="2" md="3" sm="4" xs="4">
              <v-menu

                v-model="menu1"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="date1"
                    label="Fecha Inicial"
                    prepend-icon="mdi-calendar-arrow-right"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                
                <v-date-picker 
                  locale="es"
                  v-model="date1" 
                  @input="menu1 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
        
            <!-- FECHA FINAL -->
            <v-col cols="12" xl="2" lg="2" md="3" sm="4" xs="4">
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="date2"
                    label="Fecha Final"
                    prepend-icon="mdi-calendar-arrow-left"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker 
                  locale="es"
                  v-model="date2" 
                  @input="menu2 = false"></v-date-picker>
              </v-menu>
            </v-col>

            <!-- TIPODOC  -->
            <v-col cols="12" xl="2" lg="2" md="3" sm="3" xs="3">
              <v-select
                @change="consultar()"
                :items="tipodocs"
                v-model="tipodoc"
                label="Tipo documento"                
              ></v-select>
            </v-col>
          
             
            <!-- BUSCAR -->
            <v-col cols="12" xl="4" lg="4" md="3" sm="3" xs="3">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Buscar "
                single-line
                hide-details
              ></v-text-field>
            </v-col>


            <!-- DATATABLE -->
            <v-col cols="12">
              <v-data-table
                :loading="loading" 
                loading-text="Cargando..."
                :headers="headers"
                :items="coninv"
                :items-per-page="longitud"
                class="elevation-1"
                fixed-header
                height="350"
                :search="search"
                hide-default-footer
                dense
              >
                <template v-slot:item="{ item }" >
                  <tr v-on:dblclick="edit(item)">
                   
                    <td>{{ item.numcli}}</td>
                    <td>{{ item.fecha}}</td>
                    <td>{{ item.numalm}}</td>

                    <td>{{ item.numart }}</td>
                    <td>{{ item.descrip }}</td>

                    <td align= 'end'>{{ item.cant}}</td>
                    <td class="blue--text" align= 'end'>{{ item.disp}}</td>

                    <td class="red--text">{{ item.lote}}</td>
                    
                    <td align= 'end'>{{ item.tipodoc}}</td>
                    <td align= 'end'>{{ item.numdoc}}</td>
                    <td align= 'end'>{{ item.numpar}}</td>

                           <!-- 
                    <td v-if="item.Status == 0" :style="{color: getColor(item.Status) }">Abierta</td>       
                    <td v-if="item.Status == 9" :style="{color: getColor(item.Status) }">Pedida</td>        -->
                  </tr>
               </template>
              </v-data-table>

            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    
    </v-col>  
  </v-row>  
</v-container>
</template>

<script>
  import {mapGetters, mapActions} from 'vuex'

  var accounting = require("accounting");
  var base64 = require('base-64');
  // import excelMixin from '@/mixins/excelMixin.js';
  // import jsPDF from 'jspdf'
  // import autoTable from 'jspdf-autotable';
  
  export default {
    data () {
      return {
        //FECHAS
        date1: new Date().toISOString().substr(0, 10),
        date2: new Date().toISOString().substr(0, 10),
        
        menu1: false,
        menu2: false,

        // SELECTOR TIPODOC
        tipodocs: ['Entradas', 'Salidas'],
        tipodoc: 'Cotización',
        e1:'Pedidos',
        loading: true,
        page: 1,
        pageCount: 0,
        itemsPerPage: 10,
        search: '',
        longitud: 5,
       
        headers: [
          
          { text: 'Cliente', align: 'star', value: 'numcli' },
          { text: 'Fecha', align: 'star', value: 'fecha' },
          { text: 'Alm', align: 'star', value: 'numalm' },

          { text: 'Articulo', align: 'star', value: 'numart' },
          { text: 'Descripción', align: 'star', value: 'descrip' },
          
          { text: 'Cantidad', align: 'star', value: 'cant' },
          { text: 'Disponible', align: 'star', value: 'disp' },

          { text: 'Lote', align: 'star', value: 'lote' },
          
          { text: 'Tipo', align: 'end', value: 'tipodoc' },  
          { text: 'Documento', align: 'end', value: 'numdoc' },
          { text: 'Par', align: 'end', value: 'numpar' },


        ],

        Docum: [],
        coninv: []
      }
    },

    computed:{
      ...mapGetters('Login',['getdatosUsuario','getClientes','getModo','getInfoVend']),
    },

    created () {
      //Recibir parametro de tipo de Documento a Abrir. 
       console.log("Params",this.$route.params)
      
      // this.tipodocumento= this.$route.params.tipodocumento
      // this.tipodoc = "Entradas"

      // Definir valores iniciales 
      // Fecha inicial Primero de mes.
      let date0 = new Date()
      let mes = date0.getMonth()
      let anio = date0.getFullYear()
            
      // console.log(mes, anio)
      this.date1 = new Date(anio, mes, 1).toISOString().substr(0, 10)
      this.consultar()

    },

    methods:{
      ...mapActions('documento',['addDocumento']),

      // FORMATO PDF
      // crearPDF() {
      //   var columns = [
      //       { title: 'ID',        dataKey: 'Numdoc' },
      //       { title: 'Fecha' ,    dataKey: 'Fecha' },
      //       { title: 'Importe',   dataKey: 'importe' },
      //       { title: 'Descuento', dataKey: 'descuento' },
      //       { title: 'Iva',       dataKey: 'impuesto1' },
      //       { title: 'Total',     dataKey: 'total' },
      //       { title: 'Divisa',    dataKey: 'divisa' },
      //       { title: 'Tc',        dataKey: 'Tc' },
      //       { title: 'Estatus',   dataKey: 'Status' },
      //   ];

      //   var doc = new jsPDF('p','pt');
      //   doc.autoTable(columns, this.Docum, {
      //       styles: {minCellWidth: 50, halign: 'left'},
      //       columnStyles: {
      //         0: {cellWidth: 'auto'},
      //         1: {cellWidth: 'auto'},
      //         2: {cellWidth: 'auto'},
      //         3: {cellWidth: 'auto'},
      //         4: {cellWidth: 'auto'},
              
      //         },
      //       margin: {top: 60},
      //       didDrawPage: function(data) {
      //         doc.text("Consulta de Documentos", 40, 30);
      //     }
      //   });
      //   doc.save('Documentos.pdf');
      // },


      // Consulto todos los clientes
      consultar(){
        this.Docum = []
        // console.log(this.tipodoc)
        var payload = {
          numart: '', 
          numalm: '', 
          tipodoc:'',
          numcli: ''
        }
        
        // if(this.tipodoc == 'Pedido'){
        //   payload.tipodoc = ' P'
        // }else{
        //   payload.tipodoc = ' Q'
        // }

        this.loading = true

        // if(this.getModo == 'vendedor'){
        //   payload.numvend = this.getInfoVend.Numvend
        // }else{
        //   payload.numcli = this.getdatosUsuario.numcli
        // }

        // console.log("payload",payload)
        
        this.$http.get('v2/kardex.list',payload).then(response => {
           console.log("conivn", response)
          //Calcular el Total. 
          if (response.data.result !== null) {
            this.coninv = response.data.result
            this.coninv.forEach ( element => {
              element.cant = accounting.unformat(element.cant)
              element.disp = accounting.unformat(element.disp)
              element.cant = accounting.formatNumber(element.cant,2)
              element.disp = accounting.formatNumber(element.disp,2)
            })
            this.longitud= this.coninv.length
            console.log("conivn", this.coninv)

          }

        }).catch(function(error){
          console.log(error)
        }).finally(()  => this.loading = false) 

      },


      edit(tipodoc){
        this.addDocumento(tipodoc)
        var encodedData = base64.encode(tipodoc.Iddocum);
        var doc = {doc: encodedData}
        this.$router.push({name:'documento', params: {info: doc, origen: 'cotizacion'}}).catch(err => {console.log(err)})
      },

      getColor (status) {
        if (status == 0) return 'red'
          else if (status == 9) return 'green'
        else return 'green'
      },

      exportar(){
        let xls = {
          data:      this.Docum,
          tHeaders : ['ID doc', 'Nombre', 'Tipo', 'Fecha', 'Total', 'Divisa', 'Estatus',],
          filterVal: ['Numdoc', 'nomcli', 'Tipodoc', 'Fecha', 'importe', 'divisa', 'Status'],
          nomfile :  "Consulta de Documentos"
        }
        // console.log(xls)
         this.exportarXls(xls)
      },


    }
  };
</script>